//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Page',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  head() {
    const imageUrl =
      this.$config.axios.browserBaseURL.replace('api/', '') +
      `assets/637726776459452791/rings.jpg`

    return {
      title: this.content.title.fieldValue.value,
      description: this.content.description.fieldValue.value,
      meta: [
        {
          name: 'og:title',
          content: this.content.title.fieldValue.value,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          property: 'og:image:type',
          content: 'image/jpeg',
        },
        {
          name: 'og:description',
          content: this.content.description.fieldValue.value,
        },
      ],
    }
  },
}
